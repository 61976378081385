import './bootstrap';
import toastr from "toastr/build/toastr.min.js";

$(function() {
    const globalSelection = $("#global_set_client");

    if (globalSelection.length) {
        axios.get(`/api/frontend/basic/clients?sort=business_name&page_size=99999`, {}).then(function (content) {
            content.data.data.forEach((client) => {
                globalSelection.append(`<option value='${client.id}'>${client.business_name}</option>`);
            });
            window.dispatchEvent(new Event('clientListLoaded'));
        }).catch((e) => {
            toastr.options = {"closeButton": true, "progressBar": true};
            toastr.warning("Unable to fetch client list: " + e);
        });

        globalSelection.on("change", function() {
            $(this).closest('form').submit();
        });
    }
});
